export const SongStarterIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    width='640'
    height='640'
    viewBox='0 0 640 640'
    {...props}
  >
    <g transform='matrix(1.3333333,0,0,-1.3333333,0,640)'>
      <g transform='scale(0.1)'>
        <path d='m 533.789,2387.02 c -11.391,455.85 163.18,894.86 459.199,1236.43 170.822,261.6 832.252,176.31 1080.102,57.9 183.74,-71.63 371.31,-189.89 502.58,-339.13 -28.47,-2.08 -56.94,-4.15 -85.42,-6.23 -35.5,1.77 -71.01,3.55 -106.52,5.33 -97.74,-2.93 -196.2,-12.61 -286.45,-47.47 -458.53,-135.87 -741.46,-669.63 -610.27,-1124.17 101.37,-518.97 708.63,-842.93 1198.95,-666.65 111.92,27.05 178.2,96.8 72.79,-61.19 C 2621.48,1249.01 2446.33,1078.37 2237.63,969.27 2008.99,845.621 1734.11,759.18 1473.62,783.711 904.336,1097.99 528.535,1740.18 533.789,2387.02' />
        <path d='m 4245.9,2687.7 c 176.4,-1115.5 -717,-2155.802 -1840,-2157.302 -355.5,18.903 -295.7,10.903 -19.2,136.102 224.8,131 442,299.699 595.3,517.2 312.6,415.2 445.7,983.6 325.9,1492.3 -142.9,680.7 -705.2,1267.4 -1396,1398.3 -36,5.7 -72.7,12 -110.1,18.8 -35.7,6.6 -70.7,13.4 -105,20.5 33.6,43.9 94.7,45.8 143,68.2 113.5,34.6 229.3,52.9 345.8,74.2 966.8,130.6 1917.1,-610.2 2060.3,-1568.3' />
      </g>
    </g>
  </svg>
)
