import { Grid, Container, AppBar } from '@mui/material'
import { LinkTo } from './LinkTo'
import { styled } from '@mui/material/styles'
import { backgroundCSS } from './backgroundCSS'
import { SongStarterLogo } from './SongStarterLogo'
import { useWindowScroll } from 'hooks/useWindowScroll'
export const HEADER_HEIGHT_SCROLLED = 64
const HEADER_HEIGHT = 85

const Header = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'headerScrolled' && prop !== 'menuOpen',
})<{ headerScrolled: boolean; menuOpen: boolean }>(
  ({ theme, headerScrolled, menuOpen }) => ({
    height: headerScrolled ? HEADER_HEIGHT_SCROLLED : HEADER_HEIGHT,
    top: '0px',
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.appBar + 10, // +10 to overlay the mobile player
    transition: 'height 0.4s cubic-bezier(0, 0, 0, 1) 0s',
    overflow: 'hidden',
    boxShadow: 'unset',
    ...(headerScrolled || menuOpen
      ? {
          ...backgroundCSS,
        }
      : {
          background: 'unset',
        }),
    ...(menuOpen && {
      height: '100%',
    }),
  }),
)

const GridLogo = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  width: 360,
  minWidth: 360,
  [theme.breakpoints.down('lg')]: {
    width: 160,
    minWidth: 160,
  },
  '> a': {
    color: '#fff',
  },
}))

type SongStarterHeaderTypes = {
  scrolled?: boolean
  scrollFrom?: number
}

export const dummyUserData = {
  firstNamePers: 'A',
  lastNamePers: 'B',
  id: 'ABC',
}

export const SongStarterHeader = ({
  scrolled,
  scrollFrom = 50,
}: SongStarterHeaderTypes) => {
  const { y } = useWindowScroll()
  const headerScrolled = scrolled || y > scrollFrom

  return (
    <Header headerScrolled={headerScrolled} menuOpen={false}>
      <Container maxWidth='xl'>
        <Grid
          container
          flexWrap='nowrap'
          sx={{
            height: headerScrolled ? HEADER_HEIGHT_SCROLLED : HEADER_HEIGHT,
            transition: 'height 0.4s cubic-bezier(0, 0, 0, 1) 0s',
          }}
          alignItems='center'
        >
          <GridLogo item>
            <LinkTo href='/'>
              <SongStarterLogo />
            </LinkTo>
          </GridLogo>
        </Grid>
      </Container>
    </Header>
  )
}
