import React from 'react'
import { Typography, Box } from '@mui/material'
import { SongStarterIcon } from 'icons/SongStarterIcon'

export const SongStarterLogo = () => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      height: '40px',
      alignItems: 'center',
      svg: {
        width: '36px',
        height: '36px',
        marginRight: '12px',
        color: 'inherit',
        fill: 'currentColor',
      },
    }}
  >
    <SongStarterIcon />
    <Typography
      variant='subtitle2'
      sx={{
        color: 'inherit',
        letterSpacing: -0.1,
      }}
    >
      SongStarter
    </Typography>
  </Box>
)
