import React from 'react'
import Link from 'next/link'
import { styled, Link as MuiLink, TypographyProps } from '@mui/material'

const DisabledDiv = styled('div')(() => ({
  width: '100%',
  height: '100%',
}))
interface LinkToTypes extends TypographyProps {
  href: string
  children?: React.ReactNode
  underline?: 'none' | 'hover' | 'always'
  disabled?: boolean
}

export const LinkTo = ({
  href,
  children,
  underline = 'none',
  disabled = false,
  ...props
}: LinkToTypes) => {
  if (disabled) return <DisabledDiv>{children}</DisabledDiv>
  return (
    <Link href={href} passHref>
      {/**@ts-ignore*/}
      <MuiLink underline={underline} href={href} {...props}>
        {children}
      </MuiLink>
    </Link>
  )
}
